import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/senior_follies.png";

const SeniorFollies = () => {
  return (
    <Show
      poster={poster}
      title={"Senior Follies"}
      by={"Billie Wayne St. John"}
      date={"October 13 - October 28, 2023"}
      body={`The rocking is not done in chairs at the Pleasant Valley Retirement
Home, especially since Howard discovered Viagra! The feisty divorcee
and lovely widow who are constantly scurrying out of his reach and
refuse to play strip poker or skinny dip in the hot tub, breathe a
sigh of relief when a new resident actually seems to enjoy Howards
advances – Don’t miss the hilarity in this wonderful comedy`}
    />
  );
};

export default SeniorFollies;
