import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/my_hero.jpg";

const MyHero = () => {
  return (
    <Show
      poster={poster}
      title={"My Hero"}
      by={"Norm Foster"}
      date={"June 13 - June 28, 2025"}
      body={"Description coming soon."}
    />
  );
};

export default MyHero;
