import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/all_the_kings_women.jpg";

const AllTheKingsWomen = () => {
  return (
    <Show
      poster={poster}
      title={"All The Kings Women"}
      by={"Luigi Januzzi"}
      date={"March 29 - April 13, 2024"}
      body={`The story of Elvis Presley told through the eyes of 17 women! Some enthralled! Some appalled! ALL OBSESSED! A
fast paced series of 5 comedic plays and 3 monologues based on the Life of Elvis Presley. From Tupelo
Mississippi where 11 year old Elvis wanted a BB gun instead of a guitar, to Richard Nixon’s office, to Andy
Warhol’s studio, from Cadillac salesman to Graceland guards, this is a touching, bring-the-family comedy with
a heart that captures the effects that fame, generosity & just being a nice guy can bring to others!`}
    />
  );
};

export default AllTheKingsWomen;
