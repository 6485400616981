import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/bluebirds.jpg";

const Bluebirds = () => {
  return (
    <Show
      poster={poster}
      title={"Bluebirds"}
      by={"Vern Thiessen"}
      date={"April 4 - April 19, 2025"}
      body={"Description coming soon."}
    />
  );
};

export default Bluebirds;
