import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/bedtime_stories.jpg";

const BedtimeStories = () => {
  return (
    <Show
      poster={poster}
      title={"Bedtime Stories"}
      by={"Norm Foster"}
      date={"January 17 - February 1, 2025"}
      body={"Description coming soon."}
    />
  );
};

export default BedtimeStories;
