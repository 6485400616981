import React, { useEffect, useState } from "react";

const Card = ({ card }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const importedImage = await import(`../assets/${card.image}`);
        setImage(importedImage.default);
      } catch (error) {
        console.error(`Failed to load image: ${card.image}`, error);
      }
    };
    loadImage();
  }, [card.image]);

  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href={card.link}>
        {image && (
          <img
            className="rounded-t-lg w-full object-contain"
            src={image}
            alt={card.title}
          />
        )}
      </a>
      <div className="p-5">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {card.title}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {card.by}
        </p>
        <p className="mb-3 font-normal text-xl text-gray-700 dark:text-gray-400">
          {card.date}
        </p>
        <a
          href={card.link}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-primary-dark focus:ring-4 focus:outline-none focus:ring-primary-lighter dark:bg-primary dark:hover:bg-primary-dark dark:focus:ring-primary-darker"
        >
          Read more
          <svg
            className="w-3.5 h-3.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Card;
