import React from "react";
import Card from "../components/Card";
import cards from "../data/shows.json";

const Current = () => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center my-8">
          2024-2025 Season
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-16 md:gap-12 md:grid-cols-2">
        {cards.season2024.map((card) => (
          <Card key={card.link} card={card} />
        ))}
      </div>
    </div>
  );
};

export default Current;
