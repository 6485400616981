import React from "react";

const Show = ({ poster, title, by, date, body }) => {
  return (
    <div className="min-h-[80vh] text-gray-700 dark:text-gray-300 flex-col flex items-center justify-center p-8 gap-8">
      <img
        className="object-cover object-center max-h-[32rem] rounded-3xl"
        src={poster}
        alt={`Poster for ${title}`}
      />
      <div className="max-w-screen-lg">
        <h1 className="font-header text-4xl text-center mt-8">{title}</h1>
        <p className="text-center text-2xl font-header my-4">By {by}</p>
        <p className="text-center text-xl font-header my-8">{date}</p>
        <p>{body}</p>
      </div>
    </div>
  );
};

export default Show;
