import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/night_watch.jpg";

const NightWatch = () => {
  return (
    <Show
      poster={poster}
      title={"Night Watch"}
      by={"Lucille Fletcher"}
      date={"October 18 - November 2, 2024"}
      body={"Description coming soon."}
    />
  );
};

export default NightWatch;
