import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/the_long_weekend.jpg";

const TheLongWeekend = () => {
  return (
    <Show
      poster={poster}
      title={"The Long Weekend"}
      by={"Norm Foster"}
      date={"June 14 – June 29, 2024"}
      body={`During a weekend visit between two couples, the true nature of the relationships come to the surface as the
four so-called ‘best friends’ slowly discover how they really feel about each other. Successful lawyer Max and
his wife Wynn are about to show off their beautiful new summer home to their best friends, Roger and Abby. But
it doesn’t take long to uncover the true feelings behind this long friendship. What is meant to be a relaxing
holiday turns into a battle of wits as their pasts catch up with them, the gloves come off and old scores get
settled in comedic fashion.`}
    />
  );
};

export default TheLongWeekend;
