import React from "react";
import Show from "../../components/Show";
import poster from "../../assets/rabbit_hole.jpg";

const RabbitHole = () => {
  return (
    <Show
      poster={poster}
      title={"Rabbit Hole"}
      by={"David Lindsay-Abaire"}
      date={"January 19 - February 3, 2024"}
      body={`Becca and Howie Corbett have everything a family could want, until a life-shattering accident turns their
world upside down and leaves the couple drifting perilously apart. Rabbit Hole charts their bittersweet search
for comfort in the darkest of places and for a path that will lead them back into the light of day. Winner of the 2007 Pulitzer prize.`}
    />
  );
};

export default RabbitHole;
