import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Archive from "./pages/Archive";
import Auditions from "./pages/Auditions";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Current from "./pages/Current";
import Sponsors from "./pages/Sponsors";
import NavBar from "./components/NavBar.js";
import Footer from "./components/Footer.js";
import AllTheKingsWomen from "./pages/shows/AllTheKingsWomen";
import TheLongWeekend from "./pages/shows/TheLongWeekend";
import SeniorFollies from "./pages/shows/SeniorFollies";
import RabbitHole from "./pages/shows/RabbitHole";
import Bluebirds from "./pages/shows/Bluebirds.js";
import NightWatch from "./pages/shows/NightWatch.js";
import BedtimeStories from "./pages/shows/BedtimeStories.js";
import MyHero from "./pages/shows/MyHero.js";

const App = () => {
  return (
    <div className="dark:bg-gray-900 min-h-screen">
      <NavBar />
      <div className="pt-16">
        <Routes>
          <Route path="/" element={<Current />} />
          <Route path="/about" element={<About />} />
          <Route path="/shows" element={<Current />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/auditions" element={<Auditions />} />
          <Route
            path="/shows/all-the-kings-women"
            element={<AllTheKingsWomen />}
          />
          <Route path="/shows/the-long-weekend" element={<TheLongWeekend />} />
          <Route path="/shows/rabbit-hole" element={<RabbitHole />} />
          <Route path="/shows/senior-follies" element={<SeniorFollies />} />

          <Route path="/shows/bluebirds" element={<Bluebirds />} />
          <Route path="/shows/night-watch" element={<NightWatch />} />
          <Route path="/shows/bedtime-stories" element={<BedtimeStories />} />
          <Route path="/shows/my-hero" element={<MyHero />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
